import React from 'react'
// import { Link } from 'gatsby'
import { Row, Col, Form, FormGroup } from 'reactstrap'
import styles from './contact-form.module.scss'
import ContactInput from './contact-input'
import DBButton from './db-button'

export default data => (
  <Form name="contact" method="post" data-netlify="true" action="/thankyou">
    <div className={styles.contactTop}>
      <Row>
        <Col xs="12" lg="6">
          <FormGroup>
            <ContactInput placeholder="Your name" type="text" name="contact_name" required="true" />
          </FormGroup>
          <FormGroup>
            <ContactInput placeholder="Company Name" type="text" name="contact_company" />
          </FormGroup>
        </Col>
        <Col xs="12" lg="6">
          <FormGroup>
            <ContactInput placeholder="Email Address" name="contact_email" type="email" required="true" />
          </FormGroup>
          <FormGroup>
            <ContactInput
              placeholder="Phone Number"
              type="tel"
              title="numbers only"
              name="contact_phone"
            />
          </FormGroup>
        </Col>
        <Col xs="12" lg="12">
          <FormGroup>
            <textarea
              name="contact_message"
              id="contact_message"
              className="form-control"
              placeholder="Your Message"
              rows="6"
              required
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
    <div className="contact-bottom a-hover">
      {/* <div className={styles.terms}>
        <label>
          <input type="checkbox" required="true" value="I agree to the Terms and Conditions" />
          <span>I agree to the <Link to="/terms" target="_blank">Terms and Conditions</Link></span>
        </label>
      </div> */}
      <DBButton label='Start your Journey' id="submit" type="submit" className="submit" />      
      <input type="hidden" name="action" value="contact_form_submission" />
      <input type="hidden" name="form-name" value="contact" />
    </div>
  </Form>
)
