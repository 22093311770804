import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import AOS from 'aos'
import DBButton from './db-button'

const scrollTo = () => {
  document.getElementById('main-content').scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
}

export class Summary extends Component {
  componentDidMount() {
    AOS.init({ disable: 'mobile' })
  }

  render() {
    return (
      <section className="summary">
        <Container>
          <Row className="align-items-center">
            <Col lg="7" className="summary-image order-lg-2">
              <div className="device-images" data-aos="fade-down" data-aos-duration="450">
                {this.props.data.summaryImage ? (
                  <img src={this.props.data.summaryImage.file.url} alt={this.props.data.summaryImage.file.fileName} />
                ) : (
                  ''
                )}
              </div>
            </Col>
            <Col lg="5" className="order-lg-1">
              <div className="content">
                <h1 data-aos="fade-down" data-aos-duration="450" className="section-title">
                  {this.props.data.project ? this.props.data.project : this.props.data.pageTitle}
                </h1>
                {this.props.data.intro && this.props.data.intro.childMarkdownRemark.html ? (
                  <div
                    data-aos="fade-down"
                    data-aos-duration="450"
                    className="dyn-content"
                    dangerouslySetInnerHTML={{ __html: this.props.data.intro.childMarkdownRemark.html }}
                  />
                ) : (
                  ''
                )}
                <div data-aos="fade-up" data-aos-duration="450">
                  <DBButton className="findoutmore" label="Find out more" onClick={scrollTo}/>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}

export default Summary
