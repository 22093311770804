import React from 'react'
import styles from './contact-form.module.scss'

class ContactInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      inputValue: ''
    }
  }
  updateInputValue(evt) {
    this.setState({
      inputValue: evt.target.value
    });
    if (evt.target.value !== ''){
      this.setState(state => ({
        active: true,
      }))
    } else {
      this.setState(state => ({
        active: false,
      }))
    }
  }

  render() {
    
    return (
      <div>
        <input
              type={this.props.type}
              className="form-control"
              name={this.props.name}
              id={this.props.name}
              placeholder={this.props.placeholder}
              value={this.state.inputValue}
              onChange={(evt) => this.updateInputValue(evt)}
              pattern={this.props.pattern}
              title={this.props.title}
              required={this.props.required}
            />
            <label className={this.state.active ? styles.active : null} htmlFor={this.props.name}>{this.props.placeholder}</label>
      </div>
    )
  }
}

export default ContactInput