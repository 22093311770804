import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import ContactForm from '../components/contact-form'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween, Timeline } from 'react-gsap'


if (typeof window !== `undefined`) {
  var w = window.innerWidth
}

export default () => (
  <section className="contact-section">
    <Container>
      <Row>
        <Col lg="8" className="mx-auto">
          <div data-aos="move-up" data-aos-duration="450" className="lets-talk">
            <div className="let-talk-inner text-center">
              <h2>Contact us</h2>
              <p>
                We love discussing potential projects and meeting new businesses. If you’d like to find out what we can do for you please fill in the form below with the details of your project and we’ll get back to you.
              </p>
              <ContactForm />
            </div>
          </div>
        </Col>
        <Col lg="8" className="mx-auto">
          <div data-aos="fade-in" data-aos-duration="450">
             <div className="contact-info">
                <div className="contact-links">
                  <a href="tel:01278444975">
                    <span>T.</span>01278 444 975
                  </a>
                  <a href="mailto:hello@doublebrace.com">
                    <span>E.</span>hello@doublebrace.com
                  </a>
                </div>
                <h6>Double Brace, The Forge Business Centre, Church Rd, West Huntspill, Highbridge TA9 3RN</h6>
              </div>
          </div>
        </Col>
      </Row>
    </Container>
    

    {w >= 991 ? (
      <Controller>
        <Scene indicators={false} duration="100%" triggerElement=".contact-section" triggerHook={0.9}>
          <Timeline wrapper={<div className="contact-shapes" />}>
            <Tween position="0" from={{ bottom: '0vw' }} to={{ bottom: '12vw' }}>
              <div className="shapes circle--2 bounce" />
            </Tween>
            <Tween position="0" from={{ bottom: '-5vw' }} to={{ bottom: '4vw' }}>
              <div className="shapes circle--6 bounce" />
            </Tween>
            <Tween position="0" from={{ bottom: '-20vw' }} to={{ bottom: '15vw' }}>
              <div className="shapes rectangle--1 bounce" />
            </Tween>
          </Timeline>
        </Scene>
      </Controller>
    ) : (
      ''
    )}
  </section>
)
